









import * as d3 from 'd3';
import Vue from 'vue';
// @ts-ignore
import * as topojson from 'topojson-client';
import { default as franceMap } from '../assets/depart_france_2021_topo.json';
import { default as parisMap } from '../assets/depart_paris_2021_topo.json';
import { default as islandsMap } from '../assets/depart_islands_2021_topo.json';
import { default as belgiumMap } from '../assets/depart_belgium_2021_topo.json';

export default Vue.extend({
    name: 'Map',
    props: ['hideBelgium'],
    data() {
        return {
            departementsSelected: [] as string[],
            topoData: {} as any,
            path: {} as any,
            waitForResize: null as any,
        };
    },
    methods: {
        drawFrance() {
            let mapData = franceMap,
                containerSize = document.getElementById('container')!.getBoundingClientRect(),
                departamentsOfParis = ['92', '93', '94', '75'];
            this.topoData.france = topojson.feature(mapData, {
                type: 'GeometryCollection',
                geometries: mapData.objects['depart_france_2021'].geometries,
            });

            let projection = d3
                .geoMercator()
                .fitSize([containerSize.width * 0.8, containerSize.height], this.topoData.france);
            this.path.france = d3.geoPath().projection(projection);
            let tooltip = d3.select('#franceContainer').append('div').attr('class', 'tooltip'),
                svg = d3
                    .select('#franceContainer')
                    .append('svg')
                    .attr('id', 'svgFrance')
                    .attr('height', '100%')
                    .attr('width', '100%')
                    .attr('class', 'map');

            //Draw Map
            svg.append('g')
                .selectAll('path')
                .data(this.topoData.france.features)
                .enter()
                .append('path')
                .attr('d', this.path.france)
                .attr('class', 'francePath')
                .attr('fill', (d: any) =>
                    departamentsOfParis.indexOf(d.properties.uniqueId) == -1 ? 'white' : '#003653'
                )
                .attr('id', (d: any) => {
                    if (departamentsOfParis.indexOf(d.properties.uniqueId) == -1) {
                        return `_${d.properties.uniqueId}`;
                    }
                    return '';
                })
                .style('stroke', (d: any) =>
                    departamentsOfParis.indexOf(d.properties.uniqueId) == -1 ? '#0b5177' : '#003653'
                )
                .style('stroke-width', '0.5')
                .style('cursor', function (d: any) {
                    return departamentsOfParis.indexOf(d.properties.uniqueId) == -1 ? 'pointer' : 'default';
                })
                .on('click', (event: any, d: any) => {
                    if (departamentsOfParis.indexOf(d.properties.uniqueId) == -1) {
                        this.departmentClicked(d.properties, true);
                    }
                })
                .on('mouseover', (event: any, d: any) => {
                    if (departamentsOfParis.indexOf(d.properties.uniqueId) == -1) {
                        tooltip.style('visibility', 'visible');
                    }
                })
                .on('mousemove', (event: any, d: any) =>
                    tooltip
                        .style('top', `${event.y - 15}px`)
                        .style('left', `${event.x + 10}px`)
                        .text(d.properties.name)
                )
                .on('mouseout', (event: any, d: any) => tooltip.style('visibility', 'hidden'));
        },
        drawIsland() {
            let height = 75,
                width = 75,
                mapData = islandsMap,
                topoData = topojson.feature(mapData, {
                    type: 'GeometryCollection',
                    geometries: mapData.objects['depart_islands_2021'].geometries,
                });

            let projection = d3.geoMercator().fitSize([width, height], topoData),
                path: any = d3.geoPath().projection(projection),
                tooltip = d3.select('#islandsContainer').append('div').attr('class', 'tooltip'),
                svg = d3
                    .select('#islandsContainer')
                    .append('svg')
                    .attr('class', 'map')
                    .attr('height', height)
                    .attr('width', width);

            //Draw Map
            svg.append('svg')
                .selectAll('path')
                .data(topoData.features)
                .enter()
                .append('path')
                .attr('d', path)
                .attr('fill', 'white')
                .attr('class', 'here')
                .attr('d', (d3 as any).geoPath().projection(projection))
                .attr('id', (d: any) => `_${d.properties.uniqueId}`)
                .style('stroke', '#0b5177')
                .style('stroke-width', '0.5')
                .style('cursor', 'pointer')
                .on('click', (event: any, d: any) => {
                    this.departmentClicked(d.properties, true);
                })
                .on('mouseover', (event: any, d: any) => {
                    tooltip.style('visibility', 'visible');
                })
                .on('mousemove', function (event: any, d: any) {
                    tooltip
                        .style('top', `${event.y - 15}px`)
                        .style('left', `${event.x + 10}px`)
                        .text(d.properties.name);
                })
                .on('mouseout', function (event: any, d: any) {
                    tooltip.style('visibility', 'hidden');
                });
        },
        drawParis() {
            let height = 75,
                width = 75,
                mapData = parisMap,
                topoData = topojson.feature(mapData, {
                    type: 'GeometryCollection',
                    geometries: mapData.objects['depart_paris_2021'].geometries,
                });

            let projection = d3.geoMercator().fitSize([width, height], topoData),
                path: any = d3.geoPath().projection(projection),
                tooltip = d3.select('#parisContainer').append('div').attr('class', 'tooltip'),
                svg = d3
                    .select('#parisContainer')
                    .append('svg')
                    .attr('class', 'map')
                    .attr('height', height)
                    .attr('width', width);

            //Draw Map
            svg.append('g')
                .selectAll('path')
                .data(topoData.features)
                .enter()
                .append('path')
                .attr('d', path)
                .attr('fill', 'white')
                .attr('d', (d3 as any).geoPath().projection(projection))
                .attr('id', (d: any) => `_${d.properties.uniqueId}`)
                .style('stroke', '#0b5177')
                .style('stroke-width', '0.5')
                .style('cursor', 'pointer')
                .on('click', (event: any, d: any) => {
                    this.departmentClicked(d.properties, true);
                })
                .on('mouseover', (event: any, d: any) => {
                    tooltip.style('visibility', 'visible');
                })
                .on('mousemove', function (event: any, d: any) {
                    tooltip
                        .style('top', `${event.y - 15}px`)
                        .style('left', `${event.x + 10}px`)
                        .text(d.properties.name);
                })
                .on('mouseout', function (event: any, d: any) {
                    tooltip.style('visibility', 'hidden');
                });
        },
        drawBelgium() {
            let height = 100,
                width = 100,
                mapData = belgiumMap,
                topoData = topojson.feature(mapData, {
                    type: 'GeometryCollection',
                    geometries: mapData.objects['depart_belgium_2021'].geometries,
                });

            let projection = d3.geoMercator().fitSize([width, height], topoData),
                path: any = d3.geoPath().projection(projection),
                tooltip = d3.select('#belgiumContainer').append('div').attr('class', 'tooltip'),
                svg = d3
                    .select('#belgiumContainer')
                    .append('svg')
                    .attr('class', 'map')
                    .attr('height', height)
                    .attr('width', width);

            //Draw Map
            svg.append('g')
                .selectAll('path')
                .data(topoData.features)
                .enter()
                .append('path')
                .attr('d', path)
                .attr('fill', 'white')
                .attr('d', (d3 as any).geoPath().projection(projection))
                .attr('id', (d: any) => `_${d.properties.uniqueId}`)
                .style('stroke', '#0b5177')
                .style('stroke-width', '0.5')
                .style('cursor', 'pointer')
                .on('click', (event: any, d: any) => this.departmentClicked(d.properties, false))
                .on('mouseover', (event: any, d: any) => tooltip.style('visibility', 'visible'))
                .on('mousemove', (event: any, d: any) =>
                    tooltip
                        .style('top', `${event.y - 15}px`)
                        .style('left', `${event.x + 10}px`)
                        .text(d.properties.name)
                )
                .on('mouseout', (event: any, d: any) => tooltip.style('visibility', 'hidden'));
        },
        departmentClicked(department: any, isFrance: boolean) {
            if (department) {
                this.$emit('departmentClicked', {
                    ...department,
                    isFrance: isFrance,
                });
            }
        },
        toggleDepartement(department: any) {
            let departmentIdentifier = department.uniqueId;
            if (this.departementsSelected.indexOf(departmentIdentifier) == -1) {
                d3.select(`path#_${departmentIdentifier}`).style('fill', '#58A4BC');
                this.departementsSelected.push(departmentIdentifier);
            } else {
                d3.select(`path#_${departmentIdentifier}`).style('fill', 'white');
                this.departementsSelected.splice(this.departementsSelected.indexOf(departmentIdentifier), 1);
            }
        },
        calculateMapSize() {
            if (document.getElementById('container')) {
                let containerSize = document.getElementById('container')!.getBoundingClientRect();
                if (containerSize) {
                    //Size of France
                    this.path.france.projection(
                        d3.geoMercator().fitSize([containerSize.width, containerSize.height], this.topoData.france)
                    );
                    d3.selectAll('path.francePath').attr('d', this.path.france);

                    //Size Island
                    let alpesElement: any = document.getElementById('_06'),
                        alpesBbox = alpesElement.getBBox(),
                        alpesX = alpesBbox.x + alpesBbox.width,
                        alpesY = alpesBbox.y + alpesBbox.height;

                    document.getElementById('islandsContainer')!.style.bottom = '5px';
                    document.getElementById('islandsContainer')!.style.left = alpesBbox.x + 10 + 'px';

                    //Size France
                    let ardennesElement: any = document.getElementById('_08'),
                        ardennesSize = ardennesElement.getBBox();

                    document.getElementById('parisContainer')!.style.top = '-30px';
                    document.getElementById('parisContainer')!.style.left = ardennesSize.x + 30 + 'px';

                    if (!this.hideBelgium) {
                        //Size Belgium
                        let mancheElement: any = document.getElementById('_50'),
                            mancheSize = mancheElement.getBBox(),
                            left: any = mancheSize.x - mancheSize.width - 120;

                        document.getElementById('belgiumContainer')!.style.top = '50%';
                        document.getElementById('belgiumContainer')!.style.left = (left >= 0 ? left : 5) + 'px';
                    }
                }
            }
        },
        prepareResize() {
            clearTimeout(this.waitForResize);
            this.waitForResize = setTimeout(() => {
                this.calculateMapSize();
            }, 250);
        },
    },
    mounted() {
        this.drawFrance();
        this.drawIsland();
        this.drawParis();
        if (!this.hideBelgium) {
            this.drawBelgium();
        }
        this.calculateMapSize();
        window.addEventListener('resize', this.prepareResize);
    },
    activated() {
        window.addEventListener('resize', this.prepareResize);
    },
    deactivated() {
        window.removeEventListener('resize', this.prepareResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.prepareResize);
    },
});
